// colors
body {
  background: #000;
  font-family: Suisse;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

// font
@font-face {
  font-family: Suisse;
  src: url("/assets/fonts/SuisseIntl-Regular.otf");
  font-weight: 500;
}

@font-face {
  font-family: Suisse;
  src: url("/assets/fonts/SuisseIntl-Medium.otf");
  font-weight: 600;
}

@font-face {
  font-family: Suisse;
  src: url("/assets/fonts/SuisseIntl-SemiBold.otf");
  font-weight: 700;
}

@font-face {
  font-family: Suisse;
  src: url("/assets/fonts/SuisseIntl-Bold.otf");
  font-weight: 800;
}

.menu {
  background-image: none;
  width: 100%;
  height: 70px;
  position: fixed;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  z-index: 1000;

  @media (min-width: 767px){
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.01), #000);
    height: 140px;
  }

  .header-container {

    padding: 0;
    margin: 0 auto;

    @media (min-width: 767px) {
      box-sizing: border-box;
      height: inherit;
      padding: 30px 80px;
      position: relative;
      width: 100%;
    }

    .logo {
      top: 0;
      left: 25px;
      position: relative;
      margin: 20px 0;
      display: table;
      float: left;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      z-index: 1000;


      @media (min-width: 767px) {

      }

      a {
        font-weight: 600;
        font-size: 26px;
        color: #fff;

        @media (min-width: 767px) {
          font-size: 30px;
        }

        .logo-image {
          display: block;
          height: 30px;
          width: auto;
          max-width: none;
        }
      }
    }

    .nav {
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 20px 0;
      display: table;
      opacity: 0.0;
      background: #000;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;

      @media (min-width: 767px) {
        height: auto;
        opacity: 1.0;
        position: relative;
        float: right;
        width: auto;
        margin: 30px 0;
        padding: 0;
        display: block;
        background: none;
      }

      .nav-list {
        list-style: none;
        display: table-cell;
        vertical-align: middle;
        padding: 0 30px;

        @media (min-width: 767px) {

        }

        .nav-item {


          @media (min-width: 767px) {
            float: left;
            padding: 0 25px;
          }

          a {
            font-size: 32px;
            font-weight: 800;
            color: #fff;

            @media (min-width: 767px) {
              font-size: 15px;
            }
          }
          .social-icon {
            padding: 0 10px;

            &:first-child {
              padding: 0 10px 0 0;
            }

            @media(min-width: 767px) {
              padding: 0 10px;
            }

            svg {
              font-size: 24px;

              @media (min-width: 767px) {
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    .burger-container {
      right: 8px;
      top: 5px;
      float: right;
      position: relative;
      display: inline-block;
      height: 56px;
      width: 56px;
      cursor: pointer;
      transition: rotate(0deg);
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: transparent;

      .burger {
        width: 18px;
        height: 8px;
        position: relative;
        display: block;
        margin: -4px auto 0;
        top: 50%;

        .bar {
          width: 100%;
          height: 2px;
          display: block;
          position: relative;
          background: #fff;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
        }

        .top-bar {
          transform: translateY(0px) rotate(0deg);
        }

        .bottom-bar {
          transform: translateY(6px) rotate(0deg);
        }
      }
      @media (min-width: 767px) {
        display: none;
      }
    }
  }
}

.menu-opened {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;

  .header-container {

    .logo {

    }

    .nav {
      opacity: 1.0;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
    }

    .burger-container {
      transform: rotate(90deg);

      .burger {

        .bar {
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
        }
        .top-bar {
          transform: translateY(4px) rotate(45deg);
        }

        .bottom-bar {
          transform: translateY(2px) rotate(135deg);
        }
      }
    }
  }
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}

.hero {
  position: relative;
  padding-top: 0px;
  background-image: url(/assets/images/hero-dark.png);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-repeat: repeat-x;
  animation: animatedBackground 100s linear infinite alternate;
  z-index: 900;

  @media (min-width: 767px) {
    padding-top: 140px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), #000);
  }

  .hero-container {
    position: relative;
    margin: 0 auto;

    @media (min-width: 767px) {

    }

    #made-gif {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      img {
        max-width: 100%;
        padding-top: 40%;
        position:absolute;
        left:0;
        right:0;
        margin-left: auto;
        margin-right: auto;
        -webkit-animation-name: customFadeInOut;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-duration: 4s;

        -moz-animation-name: customFadeInOut;
        -moz-animation-timing-function: ease-in-out;
        -moz-animation-iteration-count: infinite;
        -moz-animation-duration: 4s;

        -o-animation-name: customFadeInOut;
        -o-animation-timing-function: ease-in-out;
        -o-animation-iteration-count: infinite;
        -o-animation-duration: 4s;

        animation-name: customFadeInOut;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 4s;

        @media (min-width: 767px) {
          padding-top: 10%;
          max-width: 80%;
        }

        &:nth-of-type(1) {
          -webkit-animation-delay: 2.25s;
          -moz-animation-delay: 2.25s;
          -o-animation-delay: 2.25s;
          animation-delay: 2.25s;
        }

        &:nth-of-type(2) {
          -webkit-animation-delay: 2s;
          -moz-animation-delay: 2s;
          -o-animation-delay: 2s;
          animation-delay: 2s;
        }

        &:nth-of-type(3) {
          -webkit-animation-delay: 1.75s;
          -moz-animation-delay: 1.75s;
          -o-animation-delay: 1.75s;
          animation-delay: 1.75s;
        }

        &:nth-of-type(4) {
          -webkit-animation-delay: 1.5s;
          -moz-animation-delay: 1.5s;
          -o-animation-delay: 1.5s;
          animation-delay: 1.5s;
        }
        &:nth-of-type(5) {
          -webkit-animation-delay: 1.25s;
          -moz-animation-delay: 1.25s;
          -o-animation-delay: 1.25s;
          animation-delay: 1.25s;
        }
        &:nth-of-type(6) {
          -webkit-animation-delay: 1s;
          -moz-animation-delay: 1s;
          -o-animation-delay: 1s;
          animation-delay: 1s;
        }
        &:nth-of-type(7) {
          -webkit-animation-delay: 0.75s;
          -moz-animation-delay: 0.75s;
          -o-animation-delay: 0.75s;
          animation-delay: 0.75s;
        }
        &:nth-of-type(8) {
          -webkit-animation-delay: 0.5s;
          -moz-animation-delay: 0.5s;
          -o-animation-delay: 0.5s;
          animation-delay: 0.5s;
        }
        &:nth-of-type(9) {
          -webkit-animation-delay: 0.25s;
          -moz-animation-delay: 0.25s;
          -o-animation-delay: 0.25s;
          animation-delay: 0.25s;
        }
        &:nth-of-type(10) {
          -webkit-animation-delay: 0s;
          -moz-animation-delay: 0s;
          -o-animation-delay: 0s;
          animation-delay: 0s;
        }
      }
    }
  }
}

.main {
  padding: 80px 25px 0px 25px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  @media (min-width: 767px) {
    padding: 140px 80px 0px 110px;

  }

  .main-container {
    color: #fff;
    margin-bottom: 120px;

    .main-caption {

      .main-title {

        h1 {
          color: #fff;
          font-size: 36px;
          font-weight: 700;
          margin-bottom: 20px;
        }
      }
    }
    .main-content {

      p {
        color: #fff;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }
}

@-webkit-keyframes customFadeInOut {
 0% {
   opacity:1;
 }
 17% {
   opacity:1;
 }
 25% {
   opacity:0;
 }
 92% {
   opacity:0;
 }
 100% {
   opacity:1;
 }
}

@-moz-keyframes customFadeInOut {
 0% {
   opacity:1;
 }
 17% {
   opacity:1;
 }
 25% {
   opacity:0;
 }
 92% {
   opacity:0;
 }
 100% {
   opacity:1;
 }
}

@-o-keyframes customFadeInOut {
 0% {
   opacity:1;
 }
 17% {
   opacity:1;
 }
 25% {
   opacity:0;
 }
 92% {
   opacity:0;
 }
 100% {
   opacity:1;
 }
}

@keyframes customFadeInOut {
 0% {
   opacity:1;
 }
 17% {
   opacity:1;
 }
 25% {
   opacity:0;
 }
 92% {
   opacity:0;
 }
 100% {
   opacity:1;
 }
}

footer {
  position: fixed;
  width: 100%;
  height: 100px;
  z-index: 9999;
  bottom: 0;
  padding: 0 20px;

  @media (min-width: 767px) {
    padding: 0 80px;
  }

    .download-button {
      float: right;
      font-size: 12px;
      font-weight: bold;
      line-height: 40px;
      margin: 20px 0px 0 0;
      position: relative;

      @media (min-width: 767px) {
        margin: 20px 0px 0 0;
      }

      .download {
        border: 2px solid;
        background: #ffffff;
        font-size: 16px;
        padding: 14px 32px;
        border-radius: 100px;
        transition: color 0.2s linear, background-color 0.2s linear;
        border-color: #ffffff;
        color: #000000;
        font-size: 14px;
      }

    }
  // }
}
